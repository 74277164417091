export default {
  "item": "qtI",
  "checkbox": "qtt",
  "details": "qtM",
  "flex": "qtP",
  "header": "qtl qtP",
  "flex-no-gap": "qtX qtP",
  "file-name": "qtC",
  "label": "qtk body-2",
  "self-icon": "qtJ",
  "einvoice-icon": "qts",
  "type-value": "qtF",
  "with-separator": "qtg",
  "dimmed": "qtD"
};
