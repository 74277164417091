export default {
  "row": "qXH",
  "icon": "qXO",
  "cell": "qXm",
  "cell-date": "qXp",
  "cell-amount": "qXx",
  "cell-status": "qXw",
  "cell-content": "qXN",
  "align-right": "qXv"
};
