export default {
  "review": "qZA",
  "review-content": "qZY",
  "review-parameters": "qZh",
  "row": "qZf",
  "row__border": "qZK",
  "label": "qZG",
  "value": "qZr",
  "error": "qZb",
  "tooltip-icon": "qZy",
  "terms-link": "qZU"
};
