export default {
  "details": "qEv",
  "details-header": "qEo",
  "details-header-amount": "qEn",
  "details-content": "qEi",
  "details-footer": "qEA",
  "invoice-status-container": "qEY body-2",
  "invoice-dates": "qEh",
  "overdue": "qEf",
  "details-list": "qEK",
  "details-list-item": "qEG",
  "details-list-item-label": "qEr body-2",
  "details-list-item-value": "qEb",
  "border-top": "qEy",
  "description": "qEU",
  "description-value": "qEj",
  "edit-button": "qEW body-2",
  "edit-icon": "qEu",
  "badge-union": "qdS",
  "edit-description": "qdc",
  "amount-disclaimer": "qdq",
  "strike-amount": "qdZ"
};
