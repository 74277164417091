export default {
  "insight-pill": "qId",
  "interactive": "qII",
  "focus": "qIt",
  "subtitle": "qIM caption",
  "hidden": "qIP",
  "missing-amounts-warning": "qIl",
  "totals": "qIX",
  "counter": "qIC title-3",
  "loading": "qIk",
  "error": "qIJ",
  "title": "qIs title-4"
};
