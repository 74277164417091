export default {
  "modal": "qtq",
  "action-container": "qtZ",
  "similar-label": "qtR",
  "info-icon": "qtQ",
  "container": "qte",
  "subtitle": "qtB caption-bold",
  "error": "qtE",
  "item-container": "qtd"
};
