export default {
  "insight-pill": "qIF",
  "insight-pill-figure": "qIg",
  "insight-pill-renderer": "qID",
  "loading": "qIV",
  "counter": "qIL",
  "error": "qIT",
  "insight-pill-label": "qIz",
  "insight-pill-subtitle": "qIa caption",
  "hidden": "qIH",
  "missing-amounts-warning": "qIO",
  "missing-amounts": "qIm"
};
