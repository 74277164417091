export default {
  "modal": "qRp",
  "container": "qRx",
  "form-container": "qRw",
  "preview-container": "qRN",
  "preview-content": "qRv",
  "switch-container": "qRo",
  "supplier-invoice-preview": "qRn",
  "header": "qRi",
  "footer": "qRA",
  "form-content": "qRY",
  "form-content-section": "qRh",
  "form-disclaimer": "qRf",
  "hidden": "qRK"
};
