export default {
  "supplier-selector-wrapper": "qdf",
  "select-supplier": "qdK",
  "dropdown": "qdG",
  "iban": "qdr",
  "card": "qdb body-2",
  "add-btn": "qdy btn btn--square btn--icon-only",
  "archived-card": "qdU",
  "title": "qdj body-1",
  "archived-title": "qdW",
  "edit-supplier-tooltip": "qdu",
  "add-supplier": "qIS btn btn--tertiary",
  "error": "qIc caption",
  "form-title": "qIq title2"
};
