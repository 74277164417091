/* import __COLOCATED_TEMPLATE__ from './edit-form.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { camelize } from '@ember/string';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import { CalendarDate, parseDate } from '@internationalized/date';
import { DatePicker, Disclaimer, NumberField } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { dropTask, restartableTask, type Task, timeout } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

// @ts-expect-error
import styles from 'qonto/components/supplier-invoices/edit-form.module.css';
// @ts-expect-error
import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { CURRENCIES, type CurrencyCode } from 'qonto/constants/international-out/currency';
import {
  EDITABLE_DESCRIPTION_INVOICE_STATUSES,
  EINVOICING_LIFECYCLE_EVENT_DESCRIPTION,
  EINVOICING_LIFECYCLE_EVENT_ERROR_STATUS_CODES,
  EINVOICING_LIFECYCLE_EVENT_STATUS_CODES,
  EINVOICING_LIFECYCLE_EVENT_WARNING_STATUS_CODES,
  GENERIC_IBAN,
  GERMAN_INVOICE_FORMATS,
  INVOICE_OR_CREDIT_NOTE_TEXT,
  INVOICE_STATUSES,
  SOURCE_TYPES,
} from 'qonto/constants/supplier-invoice';
// @ts-expect-error
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { SPEND_LIMIT_DISCLAIMER_TYPES } from 'qonto/constants/transfers';
import type SupplierInvoiceModel from 'qonto/models/supplier-invoice';
import { PayableAmountField } from 'qonto/react/components/supplier-invoices/payable-amount-field/payable-amount-field.tsx';
import { RelatedDocumentsLoading } from 'qonto/react/components/supplier-invoices/related-documents-loading';
import { SuggestedTransactionSectionWrapper } from 'qonto/react/components/supplier-invoices/suggested-transaction-section/suggested-transaction-section';
import { getAmountProperties } from 'qonto/utils/amount';
import {
  isCurrentStepToApprove,
  isCurrentStepToPay,
  userIsCurrent, // @ts-expect-error
} from 'qonto/utils/approval-workflow-state';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
// @ts-expect-error
import removeKey from 'qonto/utils/remove-key';

const XMLFormat = 'application/xml';

class FormValidationError extends Error {
  // @ts-expect-error
  constructor(...params) {
    super(...params);
    this.name = 'FormValidationError';
  }
}

class Form {
  // @ts-expect-error
  @tracked errors;
  @tracked values;

  // @ts-expect-error
  constructor(schema) {
    // @ts-expect-error
    this.schema = schema;
    this.values = { ...schema.initialValues };
    // @ts-expect-error
    this.validations = { ...schema.validationsSchema };
  }

  get isDirty() {
    return Object.entries(this.values).some(([key, value]) => {
      if (value instanceof Date) {
        // @ts-expect-error
        return !dayjs(this.schema.initialValues[key]).isSame(dayjs(value), 'day');
      }
      if (key === 'amount') {
        return this.isAmountDirty;
      }

      // @ts-expect-error
      return this.schema.initialValues[key] !== value;
    });
  }

  get isAmountDirty() {
    return (
      // @ts-expect-error
      this.schema.initialValues.amount !== this.values.amount && // @ts-expect-error
      Number(this.schema.initialValues.amount) !== Number(this.values.amount)
    );
  }

  get isValid() {
    if (this.errors) {
      return Object.values(this.errors).every(value => !value);
    }

    return true;
  }

  get isIBANObfuscated() {
    return /^(?:••••|xxxx)/i.test(this.values.iban);
  }

  submitTask = dropTask(async (values, partial, callback) => {
    // @ts-expect-error
    let { onSubmit, onSubmitFinished } = this.schema;

    await onSubmit(values, partial, callback);
    if (onSubmitFinished) {
      await onSubmitFinished(values, partial);
    }
  });

  async submit({ partial = false, afterSubmit = null } = {}) {
    if (this.isIBANObfuscated) {
      this.values.iban = null;
    }

    try {
      if (this.isValid) {
        await this.submitTask.perform(this.values, partial, afterSubmit);
      } else {
        throw new FormValidationError();
      }
    } catch (error) {
      // @ts-expect-error
      if (error.isAdapterError) {
        // @ts-expect-error
        this.errors = normalizeErrors(error.errors, this.validations);
      }
      throw error;
    }
  }

  reset() {
    // @ts-expect-error
    this.values = { ...this.schema.initialValues };
    this.errors = undefined;
    // @ts-expect-error
    this.schema.onReset();
  }

  // @ts-expect-error
  addError(field, error) {
    this.errors = { ...this.errors, [field]: error };
  }

  // @ts-expect-error
  updateField(prop, value, hasInvalidDate) {
    this.values = { ...this.values, [prop]: value };
    let error = (typeof hasInvalidDate === 'boolean' && hasInvalidDate) || false;
    this.errors = { ...this.errors, [prop]: error };
    if (prop === 'amount') {
      this.errors = { ...this.errors, currency: false };
    }
  }
}

// @ts-expect-error
function normalizeErrors(errors = [], validations) {
  let errorsObject = {};

  errors.forEach(it => {
    // @ts-expect-error
    let pathElements = it.source.pointer.split('/');
    let errorKey = pathElements.at(-1);
    errorKey = errorKey === 'value' ? 'amount' : errorKey;
    // @ts-expect-error
    let errorCode = it.code;

    // @ts-expect-error
    errorsObject[camelize(errorKey)] =
      errorCode === 'required' ? validations[errorCode] : validations[errorKey];
  });
  return errorsObject;
}

enum APPROVAL_WORKFLOW_ESTIMATE_STATE {
  CURRENT_APPROVER = 'current_approver',
  TEAM_MEMBER_APPROVER = 'team_member_approver',
  CURRENT_PAYER = 'current_payer',
  TEAM_MEMBER_PAYER = 'team_member_payer',
}

interface SupplierInvoicesEditFormSignature {
  // The arguments accepted by the component
  Args: {
    partial: boolean;
    onClose: Function;
    onCreate: Function;
    fetchApprovalWorkflowEstimateTask: Task<null, [SupplierInvoiceModel]>;
    invoice: SupplierInvoiceModel;
    supportedCurrencies: string[];
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFormElement;
}

export default class SupplierInvoicesEditFormComponent extends Component<SupplierInvoicesEditFormSignature> {
  numberField = NumberField;
  datePicker = DatePicker;
  disclaimerBlock = Disclaimer.Block;
  disclaimerInline = Disclaimer.Inline;
  payableAmountField = PayableAmountField;
  relatedDocumentsLoading = RelatedDocumentsLoading;
  suggestedTransactionSection = SuggestedTransactionSectionWrapper;

  styles = styles;

  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare localeManager: Services['localeManager'];
  @service declare modals: Services['modals'];
  @service declare errors: Services['errors'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare store: Services['store'];
  @service declare zendeskLocalization: Services['zendeskLocalization'];
  @service declare router: Services['router'];

  // @ts-expect-error
  @reads('organizationManager.organization') organization;

  @tracked amountFormatOptions: ReturnType<typeof getAmountProperties> | null = null;
  @tracked approvalWorkflowEstimate = null;

  @action
  // @ts-expect-error
  redirectToReviewDuplicatesModal(event) {
    event.preventDefault();
    let { invoice_format, ...eventPayload } = this.loggingPayload;

    // @ts-expect-error
    this.modal = this.modals.open('supplier-invoices/review-duplicates-modal', {
      invoiceId: this.args.invoice.id,
      isFullScreenModal: true,
      ...eventPayload,
      invoice_format,
    });

    this.handleDuplicatesEventLogging(eventPayload);
  }

  // @ts-expect-error
  handleDuplicatesEventLogging(payload) {
    this.segment.track('supplier-invoices_duplicates-disclaimer_clicked', {
      ...payload,
    });
  }

  get loggingPayload() {
    let isEInvoice = this.args.invoice.isEinvoice ?? false;
    let eInvoiceType = null;

    let { attachment = null, isGermanEInvoice = false } = this.args.invoice;

    let fileContentType = attachment?.file?.fileContentType ?? null;

    if (isGermanEInvoice) {
      eInvoiceType =
        fileContentType === XMLFormat
          ? GERMAN_INVOICE_FORMATS.XRECHNUNG
          : GERMAN_INVOICE_FORMATS.ZUGFERD;
    } else {
      eInvoiceType = GERMAN_INVOICE_FORMATS.OTHER;
    }

    return {
      is_einvoice: isEInvoice,
      einvoice_type: eInvoiceType,
      invoice_format: this.getFileType(fileContentType),
    };
  }

  // @ts-expect-error
  getFileType(fileType) {
    if (!fileType) return null;

    let types = {
      image: /image/,
      pdf: /pdf/,
      xml: /xml/,
    };

    return Object.entries(types).find(entry => entry[1].test(fileType))?.[0] ?? null;
  }

  form = new Form({
    // @ts-expect-error
    onSubmit: (values, partial, callback = null) =>
      partial || this.args.partial
        ? this.savePartialInvoiceTask.perform(values)
        : this.saveInvoiceTask.perform(values, {}, callback),
    // @ts-expect-error
    onSubmitFinished: async (_, partial) => {
      let { fetchApprovalWorkflowEstimateTask, invoice } = this.args;
      if (
        variation('feature--boolean-approval-workflow-for-supplier-invoices') &&
        fetchApprovalWorkflowEstimateTask &&
        partial &&
        invoice.status === INVOICE_STATUSES.toReview
      ) {
        let currentState = this.approvalWorkflowEstimateState;
        this.approvalWorkflowEstimate = await fetchApprovalWorkflowEstimateTask.perform(invoice);
        let newState = this.approvalWorkflowEstimateState;

        this._showApprovalWorkflowChangeToast(currentState, newState);
        return this.approvalWorkflowEstimate;
      }
    },
    onReset: () => this.args.onClose?.(),

    initialValues: this.defaultValues,

    validationsSchema: this.validationsSchema,
  });

  constructor() {
    // @ts-expect-error
    super(...arguments);
    // send the form instance to the parent
    this.args.onCreate?.(this.form);
    this.checkTransferLimitsTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          // @ts-expect-error
          this.sentry.withScope(scope => {
            if (errorInfo.httpStatus === 422 && error.errors?.length) {
              // @ts-expect-error
              error.errors.forEach((e, i) => {
                scope.setContext(`error_${i}`, e);
              });
            }

            this.sentry.captureException(error);
          });
        }
      });

    this.calculateAmountFormatOptions(
      (this.args.invoice.totalAmount?.currency as CurrencyCode) || CURRENCIES.EUR
    );
  }

  get approvalWorkflowEstimateState() {
    if (!this.approvalWorkflowEstimate) {
      return null;
    }

    let { id } = this.organizationManager.membership;
    let isStepToPay = isCurrentStepToPay(this.approvalWorkflowEstimate);
    let isStepToApprove = isCurrentStepToApprove(this.approvalWorkflowEstimate);
    let isUserNextInLine = userIsCurrent(this.approvalWorkflowEstimate, id);

    if (isStepToPay && isUserNextInLine) {
      return APPROVAL_WORKFLOW_ESTIMATE_STATE.CURRENT_PAYER;
    }

    if (isStepToApprove && isUserNextInLine) {
      return APPROVAL_WORKFLOW_ESTIMATE_STATE.CURRENT_APPROVER;
    }

    if (isStepToPay && !isUserNextInLine) {
      return APPROVAL_WORKFLOW_ESTIMATE_STATE.TEAM_MEMBER_PAYER;
    }

    if (isStepToApprove && !isUserNextInLine) {
      return APPROVAL_WORKFLOW_ESTIMATE_STATE.TEAM_MEMBER_APPROVER;
    }

    return null;
  }

  get defaultValues() {
    return {
      supplierName: this.args.invoice.supplierName,
      iban: this.args.invoice.iban,
      invoiceNumber: this.args.invoice.invoiceNumber,
      issueDate: this.args.invoice.issueDate,
      dueDate: this.args.invoice.dueDate,
      paymentDate: this.args.invoice.paymentDate,
      amount: this.args.invoice.totalAmount?.value,
      currency: this.currencyDefaultValue,
      description: this.args.invoice.description,
    };
  }

  get currencyDefaultValue() {
    if (variation('feature--boolean-ap-fx-out-integration')) {
      return this.args.invoice.totalAmount?.currency &&
        this.args.supportedCurrencies.includes(this.args.invoice.totalAmount.currency)
        ? this.args.invoice.totalAmount.currency
        : null;
    }

    return this.args.invoice.totalAmount?.currency || CURRENCIES.EUR;
  }

  calculateAmountFormatOptions(currencyCode: CurrencyCode | undefined | null): void {
    if (!currencyCode) {
      return;
    }

    this.amountFormatOptions = getAmountProperties(currencyCode, this.localeManager.locale);
  }

  get validationsSchema() {
    return {
      amount: this.intl.t('supplier-invoices.edit.modal.supplier-details.amount-error'),
      iban: this.intl.t('supplier-invoices.edit.modal.supplier-details.iban-error'),
      required: this.intl.t('validations.errors.blank'),
    };
  }

  get isReadonly() {
    return [INVOICE_STATUSES.paid, INVOICE_STATUSES.scheduled].includes(this.args.invoice.status);
  }

  get isInbox() {
    return [INVOICE_STATUSES.toReview, INVOICE_STATUSES.toPay].includes(this.args.invoice.status);
  }

  get showSidebarActions() {
    if (!variation('feature--boolean-approval-workflow-for-supplier-invoices')) {
      return this.isInbox;
    }

    return [
      INVOICE_STATUSES.toReview,
      INVOICE_STATUSES.toApprove,
      INVOICE_STATUSES.awaitingPayment,
    ].includes(this.args.invoice.status);
  }

  get transferLimits() {
    return this.checkTransferLimitsTask.lastSuccessful?.value;
  }

  get isRunning() {
    return this.saveInvoiceTask.isRunning || this.checkTransferLimitsTask.isRunning;
  }

  //  not 100% guarantee of swift as we now support fx-out for EUR currency as well
  get isProbablySwiftInvoice() {
    if (variation('feature--boolean-ap-fx-out-integration')) {
      return this.form.values.currency && this.form.values.currency !== CURRENCIES.EUR;
    } else {
      return this.form.values.currency !== CURRENCIES.EUR;
    }
  }

  get showSelfInvoiceSection() {
    let isEInvoice = this.args.invoice?.source === SOURCE_TYPES.E_INVOICING;
    return this.abilities.can('read self-invoice') && !isEInvoice;
  }

  get showDisclaimer() {
    let {
      hasDuplicates,
      selfInvoiceId,
      isAttachmentNonFinancial,
      isGermanEInvoice,
      hasDiscrepancies,
    } = this.args.invoice;

    if (!this.abilities.can('review supplier-invoice')) {
      return false;
    }

    if (hasDuplicates && !selfInvoiceId) {
      return true;
    }

    if (isAttachmentNonFinancial) {
      return true;
    }

    if (isGermanEInvoice && hasDiscrepancies) {
      return true;
    }

    return false;
  }

  get shouldShowEInvoiceDiscrepanciesInfoBox() {
    let { hasDiscrepancies, isGermanEInvoice } = this.args.invoice;
    return hasDiscrepancies && isGermanEInvoice;
  }

  get showGermanEinvoicingForm() {
    return (
      this.args.invoice?.isGermanEInvoice &&
      !this.args.invoice?.isAttachmentNonFinancial &&
      EDITABLE_DESCRIPTION_INVOICE_STATUSES.includes(this.args.invoice?.status)
    );
  }

  // French E-invoicing
  get showFrenchEinvoicingForm() {
    return (
      this.args.invoice?.frenchEInvoicing &&
      EDITABLE_DESCRIPTION_INVOICE_STATUSES.includes(this.args.invoice?.status)
    );
  }

  get frenchEinvoicingEventsToDisplay() {
    let events = this.args.invoice.einvoicingLifecycleEvents || [];

    // @ts-expect-error
    return events?.filter(item =>
      EINVOICING_LIFECYCLE_EVENT_STATUS_CODES.includes(item.status_code)
    );
  }

  get showFrenchEinvoicingTimeline() {
    return Boolean(this.frenchEinvoicingEventsToDisplay.length);
  }

  get dates(): {
    issueDate: CalendarDate | null;
    dueDate: CalendarDate | null;
    paymentDate: CalendarDate | null;
  } {
    return {
      issueDate: this.form.values.issueDate
        ? parseDate(dayjs(new Date(this.form.values.issueDate)).format(DATE_PICKER_FIELD_FORMAT))
        : null,
      dueDate: this.form.values.dueDate
        ? parseDate(dayjs(new Date(this.form.values.dueDate)).format(DATE_PICKER_FIELD_FORMAT))
        : null,
      paymentDate: this.form.values.paymentDate
        ? parseDate(dayjs(new Date(this.form.values.paymentDate)).format(DATE_PICKER_FIELD_FORMAT))
        : null,
    };
  }

  get frenchEinvoicingCurrentStatusCode() {
    return this.frenchEinvoicingEventsToDisplay.at(-1)?.status_code;
  }

  get frenchEinvoicingTimelineTriggerText() {
    let latestEventDescription = EINVOICING_LIFECYCLE_EVENT_DESCRIPTION(
      this.intl,
      this.frenchEinvoicingCurrentStatusCode
    );

    let htmlTemplate = `<div><span class="${styles['timeline-status-label']}">${this.intl.t('supplier-invoices.einvoicing-timeline.title')}</span><span class="ml-8">${latestEventDescription}</span></div>`;

    return htmlSafe(htmlTemplate);
  }

  get frenchEinvoicingTimelineSteps() {
    // @ts-expect-error
    return this.frenchEinvoicingEventsToDisplay.map((event, index) => {
      let description = EINVOICING_LIFECYCLE_EVENT_DESCRIPTION(this.intl, event.status_code);
      let timeFormat = this.localeManager.locale === 'en' ? 'hh:mm A' : 'HH:mm';
      let caption = dayjs(event.timestamp).format(`MMM DD, YYYY · ${timeFormat}`);
      let reason = event.reason;
      let comment = event.reason_message;
      let showPdpTooltip = description?.includes('PDP');
      let isLastEvent = index === this.frenchEinvoicingEventsToDisplay.length - 1;
      let stepIcon = this.frenchEInvoicingTimelineStepIcon(event.status_code, isLastEvent);

      return {
        icon: { type: stepIcon, filled: isLastEvent },
        description,
        caption,
        additionalInformation:
          (reason || comment) &&
          htmlSafe(`${
            reason
              ? this.intl.t('supplier-invoices.einvoicing-timeline.subtitle.reason', { reason })
              : ''
          }
          ${
            comment
              ? `${reason ? `<br/>` : ''}${this.intl.t('supplier-invoices.einvoicing-timeline.subtitle.comment', { comment })}`
              : ''
          }`),
        tooltipInfoMessage: showPdpTooltip
          ? this.intl.t('receivable-invoices.einvoicing-timeline.pdp-tooltip')
          : null,
        isLastEvent,
      };
    });
  }

  get showCreditNote() {
    let { isCreditNote } = this.args.invoice;
    return variation('feature--boolean-ap-credit-notes') && isCreditNote;
  }

  get invoiceNumberLabel() {
    return this.showCreditNote
      ? this.intl.t('supplier-invoices.edit.modal.credit-note-details.number-label')
      : this.intl.t('supplier-invoices.edit.modal.invoice-details.number-label');
  }

  get payableAmountValue() {
    let { payableAmount } = this.args.invoice || {};
    return payableAmount?.value;
  }

  get showAnalyticalPlans() {
    return (
      this.abilities.can('update supplier-invoice') &&
      variation('feature--boolean-analytical-plan-prototype')
    );
  }

  get totalAmount() {
    let { totalAmount } = this.args.invoice || {};
    // @ts-expect-error

    return this.intl.formatMoney(totalAmount?.value, { currency: totalAmount?.currency });
  }

  get totalCreditNotesAmount() {
    let { totalAmountCreditNotes } = this.args.invoice || {};

    return totalAmountCreditNotes
      ? // @ts-expect-error
        this.intl.formatMoney(totalAmountCreditNotes?.value, {
          currency: totalAmountCreditNotes?.currency,
          signus: '-',
        })
      : 0;
  }

  get totalCreditNotesAmountValue() {
    let { totalAmountCreditNotes } = this.args.invoice || {};
    return totalAmountCreditNotes ? totalAmountCreditNotes?.value : 0;
  }

  get creditNotesCount() {
    let { relatedInvoices } = this.args.invoice || {};

    return relatedInvoices?.length || 0;
  }

  get payableAmount() {
    let { payableAmount } = this.args.invoice || {};
    // @ts-expect-error
    return this.intl.formatMoney(payableAmount?.value, { currency: payableAmount?.currency });
  }

  get creditNoteAmountLabel() {
    let { relatedInvoices = [] } = this.args.invoice || {};
    return this.intl.t(
      'supplier-invoices.modals.link-credit-notes.credit-notes.credit-note-amount',
      { count: relatedInvoices.length }
    );
  }

  get showPayableAmount() {
    let { isCreditNote, relatedInvoices } = this.args.invoice || {};

    return !isCreditNote && relatedInvoices?.length;
  }

  get amountLabel() {
    return this.showCreditNote
      ? this.intl.t('supplier-invoices.edit.modal.credit-note-amount-vat')
      : this.intl.t('supplier-invoices.edit.modal.invoice-details.amount-label');
  }

  get showSuggestedCreditNotesDisclaimer() {
    let { hasSuggestedCreditNotes, isCreditNote, relatedInvoices } = this.args.invoice || {};

    return (
      variation('feature--boolean-ap-credit-notes') &&
      hasSuggestedCreditNotes &&
      !isCreditNote &&
      !relatedInvoices?.length &&
      (this.isInbox || INVOICE_STATUSES.toApprove === this.args.invoice.status)
    );
  }

  get showSuggestedTransactions() {
    return (
      variation('feature--boolean-approval-workflow-for-supplier-invoices') &&
      variation('feature--ap-improve-upload-feedback')
    );
  }

  @action goToReviewCreditNotesFlow() {
    this.segment.track('supplier-invoices_suggested-CN_clicked');
    this.router.transitionTo(
      'supplier-invoices.credit-notes-preview',
      this.args.invoice.id,
      'suggested'
    );
  }

  // @ts-expect-error
  frenchEInvoicingTimelineStepIcon(statusCode, isLastEvent) {
    let isfrenchEInvoicingWarningStatus =
      EINVOICING_LIFECYCLE_EVENT_WARNING_STATUS_CODES.includes(statusCode);
    let isfrenchEInvoicingErrorStatus =
      EINVOICING_LIFECYCLE_EVENT_ERROR_STATUS_CODES.includes(statusCode);

    if (isfrenchEInvoicingWarningStatus && isLastEvent) {
      return `${statusCode === 214 ? 'default-colour ' : ''}warning-filled`;
    }
    if (isfrenchEInvoicingWarningStatus) {
      return 'warning';
    }
    if (isLastEvent && !isfrenchEInvoicingErrorStatus) {
      return 'success';
    }
    if (isfrenchEInvoicingErrorStatus) {
      return 'reject';
    }

    return 'default';
  }

  @action
  trackTimelineOpened() {
    this.segment.track('invoice-timeline_drawer_opened', {
      source: 'AP',
      latest_status: this.frenchEinvoicingCurrentStatusCode,
    });
  }

  get faqLink() {
    return this.intl.t('supplier-invoices.einvoicing-disclaimer.url', {
      faqUrl: this.zendeskLocalization.getLocalizedArticle('fr-einvoice'),
    });
  }
  /**
   * @params {Object} { afterSubmit: this.args.onClose }
   */
  submitFormTask = restartableTask(async callbackOption => {
    let submitted = true;
    try {
      await this.form.submit(callbackOption);
    } catch (error) {
      submitted = false;
      let errorInfo = ErrorInfo.for(error);
      if (
        errorInfo.shouldSendToSentry &&
        // @ts-expect-error
        error.status !== 400 &&
        !(error instanceof FormValidationError)
      ) {
        this.sentry.captureException(error);
      }
    }
    return submitted;
  });

  @action
  // @ts-expect-error
  updateField(prop, value, hasInvalidDate) {
    this.form.updateField(prop, value, hasInvalidDate);

    if (prop === 'amount') {
      this.submitAmountTask.perform().catch(ignoreCancelation);
    }

    if (prop === 'currency') {
      this.calculateAmountFormatOptions(value);
    }

    if (['amount', 'currency'].includes(prop)) {
      this.checkTransferLimitsTask
        .perform()
        .catch(ignoreCancelation)
        .catch(error => {
          let errorInfo = ErrorInfo.for(error);
          if (errorInfo.shouldSendToSentry) {
            // @ts-expect-error
            this.sentry.withScope(scope => {
              if (errorInfo.httpStatus === 422 && error.errors?.length) {
                // @ts-expect-error
                error.errors.forEach((e, i) => {
                  scope.setContext(`error_${i}`, e);
                });
              }

              this.sentry.captureException(error);
            });
          }
        });
    }

    let partiallySaveableFields = ['supplierSnapshot'];
    if (variation('feature--boolean-approval-workflow-for-supplier-invoices')) {
      partiallySaveableFields.push('currency');
    }

    if (partiallySaveableFields.includes(prop)) {
      this.submitPartiallyTask.perform().catch(ignoreCancelation);
    }
  }

  @action
  // @ts-expect-error
  updateDate(prop, value) {
    // @ts-expect-error
    this.updateField(prop, value?.toString() || null);
  }

  @action
  // @ts-expect-error
  selectSupplier(item) {
    // @ts-expect-error
    this.form.updateField('supplierName', item.name);

    /**
     * Saving the beneficiaryId to find back the related beneficiary in the Request Form
     * https://gitlab.qonto.co/front/qonto-js/-/merge_requests/25321
     */
    // @ts-expect-error
    this.beneficiaryId = item.id;

    if (item.customOptionMessage) {
      this.segment.track('supplier-invoices_edit_use-as-supplier-name_clicked');
    } else {
      this.segment.track('supplier-invoices_edit_select-existing-supplier_clicked');
      if (item.iban) {
        // @ts-expect-error
        this.form.updateField('iban', item.iban);
      }
    }
  }

  @action
  cancelEdit() {
    this.form.reset();
  }

  submitPartiallyTask = restartableTask(async () => {
    await timeout(DEBOUNCE_MS);
    try {
      await this.form.submit({ partial: true });
    } catch (error) {
      this.localErrorHandler(error);
    }
  });

  submitAmountTask = restartableTask(async () => {
    await timeout(DEBOUNCE_MS);
    if (this.form.isAmountDirty && !this.form.errors.amount) {
      try {
        await this.form.submit({ partial: true });
      } catch (error) {
        this.localErrorHandler(error);
      }
    }
  });

  // @ts-expect-error
  localErrorHandler(error) {
    if (error.status !== 400 && !(error instanceof FormValidationError)) {
      this.errors.handleError(error);
    }
  }

  savePartialInvoiceTask = dropTask(
    async values => await this._saveInvoiceTask.perform(values, { partial: true })
  );

  saveInvoiceTask = restartableTask(
    async (values, adapterOptions = {}, callback) =>
      await this._saveInvoiceTask.perform(values, adapterOptions, callback)
  );

  _saveInvoiceTask = restartableTask(async (values, adapterOptions, callback = null) => {
    let { iban, invoiceNumber, supplierName, issueDate, dueDate, paymentDate, description } =
      values;

    if (typeof values.amount !== 'undefined') {
      this.args.invoice.totalAmount = {
        value: values.amount,
        currency: values.currency,
      };
    }

    this.args.invoice.supplierName = supplierName;
    this.args.invoice.invoiceNumber = invoiceNumber;
    this.args.invoice.issueDate = issueDate;
    this.args.invoice.dueDate = dueDate;
    this.args.invoice.paymentDate = paymentDate;
    this.args.invoice.description = description;
    // @ts-expect-error
    this.args.invoice.beneficiaryId = this.beneficiaryId;
    this.args.invoice.iban = this.form.isIBANObfuscated ? null : iban;

    try {
      await this.args.invoice.save({ adapterOptions });

      let initialStateTrackPayload = {
        // @ts-expect-error
        ...this.form.schema.initialValues,
      };

      let finalStateTrackPayload = {
        ...this.form.values,
      };

      this.segment.track('supplier-invoices_edit-save_clicked', {
        initial_state: removeKey(initialStateTrackPayload, 'iban'),
        final_state: removeKey(finalStateTrackPayload, 'iban'),
      });

      // @ts-expect-error
      this.form.schema.initialValues = this.defaultValues;
      callback?.();
    } catch (error) {
      // @ts-expect-error
      let { status } = error;

      this.args.invoice.rollbackAttributes();

      if (status === 400) {
        // @ts-expect-error
        if (error.errors.some(e => e.code === 'required')) {
          this.segment.track('supplier-invoices_edit_mandatory-fields', {
            ...(variation('feature--boolean-ap-credit-notes') && {
              origin_type: this.args?.invoice?.isCreditNote
                ? INVOICE_OR_CREDIT_NOTE_TEXT.creditNote
                : INVOICE_OR_CREDIT_NOTE_TEXT.invoice,
            }),
          });
        }
        throw error;
      } else if (status === 0 || status === 500) {
        let message = this.intl.t('supplier-invoices.edit.modal.error-toast.save-changes');
        this.toastFlashMessages.toastError(message);
        throw error;
      } else {
        this.errors.handleError(error);
      }
    }
  });

  checkTransferLimitsTask = restartableTask(async () => {
    let { amount, currency, invoiceNumber, supplierName } = this.form.values;

    if (
      this.abilities.cannot('create transfer') ||
      this.args.invoice.status === INVOICE_STATUSES.paid ||
      // @ts-expect-error
      !parseFloat(amount) > 0 ||
      this.isProbablySwiftInvoice
    )
      return;

    let debounce = macroCondition(isTesting()) ? 0 : 500;
    await timeout(debounce);

    let amountDetails = {
      amountCurrency: currency,
      amount,
    };

    // @ts-expect-error
    if (this.transfer) {
      // @ts-expect-error
      this.transfer.setProperties({ ...amountDetails });
    } else {
      // @ts-expect-error
      this.transfer = this.store.createRecord('transfer', {
        bankAccount: this.organization.mainAccount,
        organization: this.organization,
        iban: GENERIC_IBAN,
        name: supplierName || 'name',
        activityTag: 'other_expense',
        reference: invoiceNumber || 'reference',
        ...amountDetails,
      });
    }

    // @ts-expect-error
    let response = await this.transfer.confirm();
    this._manageAmountValidation(response);
    return response;
  });

  /**
   * Handles validation for amount field based on response warnings
   * @param {Object} response - Response from the transfer confirmation
   * @param {Array} response.warnings - Array of warning codes
   * @private
   */
  // @ts-expect-error
  _manageAmountValidation(response) {
    this.form.errors = { ...this.form.errors, amount: false };

    if (!response?.warnings?.length) {
      return;
    }

    let hasTransferLimitWarnings = response.warnings.some(
      // @ts-expect-error
      warning =>
        // @ts-expect-error
        warning === SPEND_LIMIT_DISCLAIMER_TYPES.AMOUNT_LIMIT ||
        // @ts-expect-error
        warning === SPEND_LIMIT_DISCLAIMER_TYPES.MONTHLY_LIMIT ||
        // @ts-expect-error
        warning === SPEND_LIMIT_DISCLAIMER_TYPES.PER_TRANSFER_LIMIT
    );

    if (hasTransferLimitWarnings) {
      this.form.errors = {
        ...this.form.errors,
        amount: this.intl.t('validations.errors.supplier-invoices.transfer-limit-exceeded'),
      };
    }
  }

  // @ts-expect-error
  _showApprovalWorkflowChangeToast(currentState, newState) {
    if (currentState === newState) {
      return;
    }

    switch (newState) {
      case APPROVAL_WORKFLOW_ESTIMATE_STATE.CURRENT_PAYER:
        this.toastFlashMessages.toastSuccess(
          this.intl.t('supplier-invoices.action-toast.ready-for-payment')
        );
        break;
      case APPROVAL_WORKFLOW_ESTIMATE_STATE.CURRENT_APPROVER:
        this.toastFlashMessages.toastSuccess(
          this.intl.t('supplier-invoices.action-toast.awaiting-approval')
        );
        break;
      case APPROVAL_WORKFLOW_ESTIMATE_STATE.TEAM_MEMBER_PAYER:
        this.toastFlashMessages.toastSuccess(
          this.intl.t('supplier-invoices.action-toast.awaiting-payment-by-team-member')
        );
        break;
      case APPROVAL_WORKFLOW_ESTIMATE_STATE.TEAM_MEMBER_APPROVER:
        this.toastFlashMessages.toastSuccess(
          this.intl.t('supplier-invoices.action-toast.awaiting-approval-by-team-member')
        );
        break;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::EditForm': typeof SupplierInvoicesEditFormComponent;
  }
}
