/* import __COLOCATED_TEMPLATE__ from './pending.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { dasherize } from '@ember/string';
import Component from '@glimmer/component';

// @ts-expect-error
import { ensureSafeComponent } from '@embroider/util';
import { Spinner } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { REQUEST_ORIGINS, REQUEST_TYPES } from 'qonto/constants/requests';
import { Member } from 'qonto/react/components/tasks/table/cell/member';

interface TaskTableItemPendingSignature {
  // The arguments accepted by the component
  Args: {
    isHighlighted?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class TaskTableItemPendingComponent extends Component<TaskTableItemPendingSignature> {
  spinner = Spinner;

  @service declare modals: Services['modals'];
  @service declare attachmentsManager: Services['attachmentsManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  member = Member;

  get activeAccounts() {
    return this.organizationManager.organization?.activeAccounts;
  }

  get requestComponentName() {
    // @ts-expect-error
    let type = dasherize(this.args.request.requestType);
    return ensureSafeComponent(`tasks/table/cell/${type}`, this);
  }

  get approveTooltipMessage() {
    // @ts-expect-error
    let { request } = this.args;
    let { requestType } = request;

    if ([REQUEST_TYPES.EXPENSE_REPORT, REQUEST_TYPES.MILEAGE].includes(requestType)) {
      if (!request.lastStep) {
        return this.intl.t('approval-workflows.requests.tooltip.approve');
      }
      return this.intl.t('reimbursements.tooltip.pay-by-transfer');
    }

    if ([REQUEST_TYPES.FLASH_CARD, REQUEST_TYPES.VIRTUAL_CARD].includes(requestType)) {
      return this.intl.t('btn.cards.approve-request');
    }

    if ([REQUEST_TYPES.MULTI_DIRECT_DEBIT_COLLECTION].includes(requestType)) {
      return this.intl.t('requests.sdd-collections.review.approve.tooltip');
    }

    return request.lastStep
      ? this.intl.t('transfers.tooltip.pay-by-transfer')
      : this.intl.t('transfers.requests.quick-approve-tooltip');
  }

  get rejectTooltipMessage() {
    // @ts-expect-error
    let { request } = this.args;
    let initiatorId = request.get?.('initiator.id') || request.initiator.id;

    if (initiatorId === this.organizationManager.membership?.id) {
      return this.intl.t('reimbursements.tooltip.cancel');
    }

    return this.intl.t('reimbursements.tooltip.reject');
  }

  handleOpenFilePreviewModal = dropTask(async event => {
    event.stopPropagation();
    // @ts-expect-error
    await this.args.request.get('attachments');

    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      deletable: false,
      // @ts-expect-error
      selectedFile: this.args.request.attachmentsFiles[0],
      // @ts-expect-error
      model: this.args.request,
      download: this.attachmentsManager.downloadAttachment,
    });
  });

  get showAttachment() {
    // @ts-expect-error
    return this.args.request.attachmentIds?.length > 0;
  }

  @action
  // @ts-expect-error
  handleSelectAccount(bankAccount) {
    this.segment.track('bank_account_picker_opened', {
      origin: REQUEST_ORIGINS.tasks,
    });
    // @ts-expect-error
    this.args.onAccountChange(this.args.request, bankAccount);
  }

  @action
  // @ts-expect-error
  stopPropagation(event) {
    event.stopPropagation();
  }

  handleRequestApprovalTask = dropTask(async () => {
    // @ts-expect-error
    await this.args.onApprove(this.args.request);
  });

  handleRequestRejectionTask = dropTask(async () => {
    // @ts-expect-error
    await this.args.onReject(this.args.request);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Tasks::Table::Item::Pending': typeof TaskTableItemPendingComponent;
  }
}
