export default {
  "sidepanel": "qMH",
  "close-icon": "qMO",
  "close-button": "qMm",
  "supplier-card": "qMp",
  "supplier-card-details": "qMx",
  "supplier-name": "qMw title-3 mb-8",
  "supplier-card-placeholder": "qMN",
  "box": "qMv",
  "box-element": "qMo",
  "related-invoices-title": "qMn",
  "placeholder-container": "qMi",
  "related-invoices-wrapper": "qMA",
  "related-invoice-placeholder": "qMY",
  "related-invoice-placeholder-inner": "qMh",
  "actions": "qMf",
  "currency": "qMK"
};
