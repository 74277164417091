/* import __COLOCATED_TEMPLATE__ from './related-documents.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { INVOICE_OR_CREDIT_NOTE_TEXT } from 'qonto/constants/supplier-invoice';

interface RelatedDocumentsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RelatedDocumentsComponent extends Component<RelatedDocumentsSignature> {
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare router: Services['router'];

  get originType() {
    // @ts-expect-error
    if (this.args.invoice.isCreditNote) return INVOICE_OR_CREDIT_NOTE_TEXT.creditNote;
    return INVOICE_OR_CREDIT_NOTE_TEXT.invoice;
  }

  // @ts-expect-error
  @action amount({ value, currency }) {
    // @ts-expect-error
    let { isCreditNote } = this.args.invoice || {};

    // @ts-expect-error
    return this.intl.formatMoney(Number(value), {
      currency,
      signus: isCreditNote ? null : '-',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::RelatedDocuments': typeof RelatedDocumentsComponent;
  }
}
