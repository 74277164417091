export default {
  "label-select": "qez",
  "puce": "qea",
  "puce-wrapper": "qeH",
  "puce-container": "qeO",
  "readonly": "qem",
  "label-display": "qep",
  "label-list-name": "qex body-1",
  "label-list-value": "qew body-2"
};
