export default {
  "row": "qPB",
  "greyed-out": "qPE",
  "supplier-name": "qPd",
  "cell": "qPI",
  "supplier": "qPt",
  "email-address": "qPM",
  "outstanding-balance": "qPP",
  "quick-actions": "qPl",
  "empty": "qPX",
  "supplier-container": "qPC",
  "grey-background": "qPk"
};
