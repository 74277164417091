export default {
  "savings-account": "qZS",
  "closed": "qZc",
  "badge": "qZq",
  "subtitle": "qZZ",
  "amount": "qZR",
  "processing": "qZQ",
  "gain": "qZe",
  "active": "qZB",
  "progress": "qZE",
  "progress-bar": "qZd",
  "progress-text": "qZI",
  "info": "qZt",
  "menu-item": "qZM body-2",
  "close-account": "qZP"
};
