export default {
  "form": "qdL",
  "form-title": "qdT title2",
  "row": "qdz",
  "col": "qda",
  "actions": "qdH",
  "buttons": "qdO",
  "collapsible-timeline": "qdm",
  "link": "qdp",
  "timeline-status-label": "qdx",
  "section": "qdw",
  "einvoicing-disclaimer": "qdN",
  "disclaimer-section": "qdv qdw",
  "related-documents-card-margin-top": "qdo"
};
