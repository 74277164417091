/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { camelize } from '@ember/string';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

import {
  FIRST_EINVOICE_TOOLTIP_DISMISSED_LOCAL_STORAGE_KEY,
  FIRST_GERMAN_EINVOICE_TOOLTIP_DISMISSAL_KEY,
  INVOICE_STATUSES,
} from 'qonto/constants/supplier-invoice';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

interface SupplierInvoicesTableSignature {
  // The arguments accepted by the component
  Args: {
    isPayLaterEligible?: boolean;
    isEligibleToInternationalTransfers?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SupplierInvoicesTableComponent extends Component<SupplierInvoicesTableSignature> {
  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare router: Services['router'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];

  // @ts-expect-error
  @reads('organizationManager.organization.legalCountry') legalCountry;

  get sortedInvoices() {
    // @ts-expect-error
    let { invoices, sortBy } = this.args;

    if (invoices && sortBy) {
      // @ts-expect-error
      let [property, option] = this.args.sortBy.split(':').map(camelize);

      let [missing, defined] = [[], []];
      // @ts-expect-error
      invoices.forEach(invoice =>
        // @ts-expect-error
        invoice[property] ? defined.push(invoice) : missing.push(invoice)
      );

      return option === 'asc' ? [...missing, ...defined] : [...defined, ...missing];
    } else {
      return invoices;
    }
  }

  get areSomeSelected() {
    // @ts-expect-error
    if (this.args.localState.isLoading) return false;
    // @ts-expect-error
    return this.args.selected.length > 0;
  }

  get areAllSelected() {
    // @ts-expect-error
    if (this.args.localState.isLoading) return false;

    // @ts-expect-error
    let nonScheduledInvoices = this.args.invoices.filter(
      // @ts-expect-error
      invoice => invoice.status !== INVOICE_STATUSES.scheduled
    );
    if (nonScheduledInvoices.length === 0) return false;
    // @ts-expect-error
    return this.args.selected.length === nonScheduledInvoices.length;
  }

  @action toggleSelectAll() {
    this.segment.track('supplier-invoices_bulk_select_all');
    if (this.areSomeSelected) {
      // @ts-expect-error
      this.args.selectItems([]);
    } else {
      // @ts-expect-error
      this.args.selectItems(
        // @ts-expect-error
        this.args.invoices
          // @ts-expect-error
          .filter(invoice => invoice.status !== INVOICE_STATUSES.scheduled)
          // @ts-expect-error
          .map(({ id }) => id)
      );
    }
  }

  // @ts-expect-error
  @action isSelected(id) {
    // @ts-expect-error
    return this.args.selected.includes(id);
  }

  // @ts-expect-error
  @action selectItem(id) {
    // @ts-expect-error
    let newArray = [...this.args.selected];
    let idIndex = newArray.indexOf(id);

    if (idIndex === -1) {
      newArray.push(id);
    } else {
      newArray.splice(idIndex, 1);
    }
    // @ts-expect-error
    this.args.selectItems(newArray);
  }

  get isItalianOrganization() {
    return this.legalCountry === 'IT';
  }

  get inboxSubtitle() {
    if (!this.isItalianOrganization) return null;

    return this.abilities.can('create transfer')
      ? this.intl.t('supplier-invoices.section.tabs.imported.empty-state.body-italy')
      : this.intl.t('supplier-invoices.section.tabs.imported.empty-state.body-italy-no-transfer');
  }

  get displayToReviewEmptyStateBlock() {
    // @ts-expect-error
    return this.args.status === INVOICE_STATUSES.toReview && !this.isItalianOrganization;
  }

  get emptyStateOptions() {
    // @ts-expect-error
    let { status } = this.args;

    let dualNavEmptyStates = {
      to_review: {
        title: this.intl.t('supplier-invoices.section.tabs.imported.empty-state.title'),
        subtitle: this.inboxSubtitle,
        lottieSrc: '/lotties/supplier-invoices/empty-state/to-review.json',
      },
      pending: {
        title: this.intl.t('supplier-invoices.section.tabs.pending-requests.empty-state.title'),
        subtitle: this.intl.t('supplier-invoices.section.tabs.pending-requests.empty-state.body'),
        lottieSrc: '/lotties/empty-state/no-request-pending.json',
      },
      scheduled: {
        title: this.intl.t('supplier-invoices.section.tabs.processing.empty-state.title'),
        subtitle: this.intl.t('supplier-invoices.section.tabs.processing.empty-state.body'),
        lottieSrc: '/lotties/supplier-invoices/empty-state/scheduled.json',
      },
      paid: {
        title: this.intl.t('supplier-invoices.section.tabs.completed.empty-state.title'),
        subtitle: this.abilities.can('create transfer')
          ? this.intl.t('supplier-invoices.section.tabs.completed.empty-state.body-authorised')
          : this.intl.t('supplier-invoices.section.tabs.completed.empty-state.body'),
        lottieSrc: '/lotties/supplier-invoices/empty-state/paid.json',
      },
      'paid,archived': {
        title: this.intl.t('supplier-invoices.section.tabs.completed.empty-state.title'),
        subtitle: this.abilities.can('create transfer')
          ? this.intl.t('supplier-invoices.section.tabs.completed.empty-state.body-authorised')
          : this.intl.t('supplier-invoices.section.tabs.completed.empty-state.body'),
        lottieSrc: '/lotties/supplier-invoices/empty-state/paid.json',
      },
    };

    // @ts-expect-error
    return dualNavEmptyStates[status];
  }

  @action
  // @ts-expect-error
  handleShowInvoice(invoice) {
    this.segment.track('supplier-invoices_details_viewed', {
      hasDuplicates: invoice.hasDuplicates,
      // @ts-expect-error
      ...this.args.trackingEventOptions,
    });
    this.router.transitionTo('supplier-invoices.show', invoice.id);

    // @ts-expect-error
    if (this.args.firstEInvoiceId === invoice.id) {
      let { organization } = this.organizationManager;
      let dismissalKey =
        organization?.legalCountry === 'DE'
          ? FIRST_GERMAN_EINVOICE_TOOLTIP_DISMISSAL_KEY
          : FIRST_EINVOICE_TOOLTIP_DISMISSED_LOCAL_STORAGE_KEY;
      // @ts-expect-error
      safeLocalStorage.setItem(dismissalKey, true);
    }
  }

  @action
  // @ts-expect-error
  handleItemKeydown(invoice, event) {
    if (event.key === 'Enter' || event.key === ' ') {
      this.router.transitionTo('supplier-invoices.show', invoice.id);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::Table': typeof SupplierInvoicesTableComponent;
  }
}
