export default {
  "row": "qlj",
  "active": "qlW",
  "dot": "qlu",
  "red": "qXS",
  "green": "qXc",
  "yellow": "qXq",
  "align-right": "qXZ",
  "animated": "qXR",
  "fadein-item": "qXQ",
  "cell": "qXe body-2",
  "cell-content": "qXB",
  "amount": "qXE body-1",
  "subtitle": "qXd caption",
  "status": "qXI",
  "cell-amount": "qXt"
};
