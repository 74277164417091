export default {
  "progress-container": "qCE",
  "line-container": "qCd",
  "dashed-line": "qCI",
  "solid-line": "qCt",
  "circle-container": "qCM",
  "circle": "qCP",
  "current-step-dot": "qCl",
  "success-icon": "qCX",
  "rejected-icon": "qCC"
};
