/* import __COLOCATED_TEMPLATE__ from './analytical-plans.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Button } from '@repo/design-system-kit';

interface AnalyticalPlansSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class AnalyticalPlans extends Component<AnalyticalPlansSignature> {
  Button = Button;
  @tracked costCenters = [null];
  @tracked codes?: [
    { id: 'PARIS11'; label: 'Paris 11' },
    { id: 'PARIS12'; label: 'Paris 12' },
    { id: 'PARIS13'; label: 'Paris 13' },
  ];
  // Placeholder for what we expect the API to return
  @tracked analyticalPlans?: {
    analytical_plans: [
      {
        id: '1';
        name: 'Restaurant';
        created_at: '';
        codes: [
          {
            code: 'PARIS11';
            label: 'Paris 11';
          },
          {
            code: 'PARIS12';
            label: 'Paris 12';
          },
        ];
      },
      {
        id: '2';
        name: 'Store';
        created_at: '';
        codes: [
          {
            code: 'PARIS11';
            label: 'Paris 11';
          },
          {
            code: 'PARIS12';
            label: 'Paris 12';
          },
        ];
      },
    ];
  };
  // @ts-expect-error
  @tracked selectedCostCenter;

  // @ts-expect-error
  @action selectCostCenter({ label }) {
    this.selectedCostCenter = label;
  }

  @action addCostCenter() {
    this.costCenters = [...this.costCenters, null];
  }

  // @ts-expect-error
  @action deleteCostCenter(index) {
    this.costCenters = this.costCenters.filter((_, i) => i !== index);
  }

  get analyticalPlanNames() {
    return [
      { id: '1', label: 'Restuarant' },
      { id: '2', label: 'Store' },
      { id: '3', label: 'Product' },
    ];
  }

  get selectedAnalyticalPlan() {
    // @ts-expect-error
    return this.args.selected ? this.args.selected : '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::AnalyticalPlans::AnalyticalPlans': typeof AnalyticalPlans;
  }
}
