export default {
  "header-cell": "qMj",
  "first-col": "qMW",
  "mid-col": "qMu",
  "outstanding-col": "qPS",
  "header-content": "qPc caption-bold",
  "active": "qPq",
  "quick-actions": "qPZ",
  "empty": "qPR"
};
