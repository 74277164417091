export default {
  "invoice-sidebar": "qQJ",
  "body": "qQs",
  "box": "qQF",
  "box-header": "qQg",
  "sidebar-box": "qQD",
  "border-top": "qQV",
  "row": "qQL",
  "greyed-out": "qQT",
  "strike-through": "qQz"
};
