export default {
  "header": "qBA",
  "subtitle": "qBY body-2",
  "date": "qBh",
  "subtitle-error": "qBf",
  "card": "qBK",
  "card-header": "qBG",
  "line-placeholder": "qBr",
  "post-closing-list": "qBb",
  "post-closing-container": "qBy",
  "post-closing-list-element": "qBU",
  "free-trial-disclaimer": "qBj",
  "description": "qBW body-2",
  "mw-100": "qBu",
  "addon-overview": "qES",
  "addons-container": "qEc"
};
