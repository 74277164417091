export default {
  "header-cell": "qlA",
  "header-content": "qlY caption-bold",
  "active": "qlh",
  "empty": "qlf",
  "type": "qlK",
  "requester": "qlG",
  "request-date": "qlr",
  "amount": "qlb",
  "receipt": "qly",
  "quick-actions": "qlU"
};
