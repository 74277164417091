/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

import { ShowMembers } from 'qonto/react/components/show-members';

interface TeamCardSignature {
  // The arguments accepted by the component
  Args: {
    isDeletable?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TeamCardComponent extends Component<TeamCardSignature> {
  disclaimerInline = Disclaimer.Inline;

  @service declare modals: Services['modals'];
  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare router: Services['router'];

  showMembers = ShowMembers;

  get hasAllMembersRevoked() {
    return (
      // @ts-expect-error
      this.args.memberships.length && this.args.memberships.every(membership => membership.revoked)
    );
  }

  @action
  renameTeam() {
    this.segment.track('teams_rename_clicked');
    this.modals.open('teams/modal/rename-team', {
      // @ts-expect-error
      team: this.args.team,
    });
  }

  @action
  viewTeamMemberships() {
    this.router.transitionTo('members', {
      // @ts-expect-error
      queryParams: { teamId: this.args.team.id },
    });
  }

  @action
  deleteTeam() {
    this.segment.track('teams_delete_clicked');

    // @ts-expect-error
    if (this.args.memberships.length) {
      return this.modals.open('teams/modal/refuse-delete-team', {
        hasAllMembersRevoked: this.hasAllMembersRevoked,
        // @ts-expect-error
        teamId: this.args.team.id,
        viewTeamMemberships: this.viewTeamMemberships,
      });
    }

    this.modals.open('teams/modal/confirm-delete-team', {
      // @ts-expect-error
      team: this.args.team,
      // @ts-expect-error
      confirmTask: this.args.onTeamDeleted,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Teams::Card': typeof TeamCardComponent;
  }
}
