/* import __COLOCATED_TEMPLATE__ from './sidebar-actions.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

// @ts-expect-error
import { STATUS } from 'qonto/constants/requests';
import {
  GERMAN_INVOICE_FORMATS,
  INVOICE_OR_CREDIT_NOTE_TEXT,
  INVOICE_STATUSES,
} from 'qonto/constants/supplier-invoice';
import { SPEND_LIMIT_TYPES, TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
// @ts-expect-error
import { isIbanFromSepaZone } from 'qonto/utils/beneficiaries';
// @ts-expect-error
import { SPEND_LIMITS_WARNINGS } from 'qonto/utils/extract-confirmation-response';
import { prepareLimitsData } from 'qonto/utils/transfers';

const XMLFormat = 'application/xml';

interface SidebarActionsSignature {
  // The arguments accepted by the component
  Args: {
    isSwift?: boolean;
    isRunning?: boolean;
    isEligibleToInternationalTransfers?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class SidebarActionsComponent extends Component<SidebarActionsSignature> {
  @service declare abilities: Services['abilities'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  get canPayByTransfer() {
    if (variation('feature--boolean-ap-fx-out-integration')) {
      return this.abilities.can('create transfer') && !this.hasTransferWarnings;
    } else {
      return (
        this.abilities.can('create transfer') && (!this.hasTransferWarnings || this.args.isSwift)
      );
    }
  }

  get membershipId() {
    return this.organizationManager.membership.id;
  }

  get canRequestTransfer() {
    return !this.args.isSwift;
  }

  get germanEInvoiceEventFields() {
    // @ts-expect-error
    let isEInvoice = this.args.invoice.isEinvoice;
    let eInvoiceType = null;

    // @ts-expect-error
    let { attachment = null, isGermanEInvoice = false } = this.args.invoice;

    if (isGermanEInvoice) {
      eInvoiceType =
        attachment?.file?.fileContentType === XMLFormat
          ? GERMAN_INVOICE_FORMATS.XRECHNUNG
          : GERMAN_INVOICE_FORMATS.ZUGFERD;
    } else {
      eInvoiceType = GERMAN_INVOICE_FORMATS.OTHER;
    }

    return {
      is_einvoice: isEInvoice,
      einvoice_type: eInvoiceType,
    };
  }

  get hasRequestTransferAbility() {
    return this.abilities.can('request transfer supplierInvoice');
  }

  get hasTransferWarnings() {
    // @ts-expect-error
    let { warnings } = this.args.transferLimits || {};

    // @ts-expect-error
    return warnings?.some(warning => SPEND_LIMITS_WARNINGS.includes(warning));
  }

  get isCtaDisplayed() {
    if (!variation('feature--boolean-approval-workflow-for-supplier-invoices')) {
      return (
        this.abilities.can('update supplierInvoice') &&
        // @ts-expect-error
        [INVOICE_STATUSES.toReview, INVOICE_STATUSES.toPay].includes(this.args.invoice?.status)
      );
    }

    return (
      this.abilities.can('update supplierInvoice') &&
      [
        INVOICE_STATUSES.toReview,
        INVOICE_STATUSES.toApprove,
        INVOICE_STATUSES.awaitingPayment,
        // @ts-expect-error
      ].includes(this.args.invoice?.status)
    );
  }

  get isPayByTransferCtaDisabled() {
    if (variation('feature--boolean-ap-fx-out-integration')) {
      return (
        this.args.isRunning ||
        this.isPayByTransferTooltipDisplayed ||
        this.isSwiftTransferButNotEligible
      );
    } else {
      return this.args.isRunning || this.isPayByTransferTooltipDisplayed || this.args.isSwift;
    }
  }

  get isDeclinedNoteDisplayed() {
    // @ts-expect-error
    let { invoice, request } = this.args;

    return (
      [INVOICE_STATUSES.toReview, INVOICE_STATUSES.toPay].includes(invoice?.status) &&
      request?.status === STATUS.DECLINED
    );
  }

  get isLinkCreditNoteCtaDisplayed() {
    return (
      variation('feature--boolean-ap-credit-notes') &&
      // @ts-expect-error
      this.args.invoice?.isCreditNote
    );
  }

  get isSubmitKycDisclaimerDisplayed() {
    return (
      this.organizationManager.membership.shouldSubmitKyc &&
      this.organizationManager.organization.kybPending
    );
  }

  get isSwiftTransferButNotEligible() {
    return this.args.isSwift && !this.args.isEligibleToInternationalTransfers;
  }

  get isPayByTransferTooltipDisplayed() {
    if (variation('feature--boolean-ap-fx-out-integration')) {
      return (
        this.args.isSwift &&
        (this.hasTransferWarnings || !this.args.isEligibleToInternationalTransfers)
      );
    }

    return this.args.isSwift && this.hasTransferWarnings;
  }

  get payByTransferTooltipText() {
    if (variation('feature--boolean-ap-fx-out-integration') && this.isSwiftTransferButNotEligible) {
      return this.intl.t('supplier-invoices.cta.international-out-unavailable');
    }

    // @ts-expect-error
    if (this.args.transferLimits) {
      // @ts-expect-error
      let { warnings, spendLimits } = this.args.transferLimits;

      if (warnings) {
        let { aboveLimitType, monthly, monthSpendings, perTransfer } = prepareLimitsData(
          warnings,
          spendLimits
        );

        // @ts-expect-error
        return aboveLimitType === SPEND_LIMIT_TYPES.MONTHLY
          ? this.intl.t('supplier-invoices.cta.request-transfer-tooltip.monthly-limit', {
              balance_monthly_transfer_limit: monthly - monthSpendings,
            })
          : this.intl.t('supplier-invoices.cta.request-transfer-tooltip.per-transfer-limit', {
              per_transfer_limit: perTransfer,
            });
      }
    }
  }

  get markAsPaidOrMatchTransactionCopy() {
    return this.isLinkCreditNoteCtaDisplayed
      ? this.intl.t('supplier-invoices.cta.match-transaction')
      : this.intl.t('supplier-invoices.cta.mark-as-paid');
  }

  openAttachmentLinkModal = dropTask(async () => {
    // @ts-expect-error
    let { invoice, submitForm } = this.args;

    if (submitForm) {
      let submitted = await submitForm.perform();
      if (!submitted) return;
    }

    this.segment.track('supplier-invoices_link-invoice_clicked', {
      page: invoice.status,
    });

    this.modals.open(
      'attachments/attachments-suggested/modal',
      {
        creditNote: invoice,
        // @ts-expect-error
        onClose: close => {
          close();
          // @ts-expect-error
          this.args.reloadInvoice();
        },
        hasSupplierInvoices: true,
        transaction: {},
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
        },
      }
    );
  });

  openTransactionsModalTask = dropTask(async () => {
    // @ts-expect-error
    let { invoice, submitForm } = this.args;

    if (submitForm) {
      let submitted = await submitForm.perform();
      if (!submitted) return;
    }

    let toastMessage = this.isLinkCreditNoteCtaDisplayed
      ? this.intl.t('supplier-invoices.success-toast.matched-transaction')
      : this.intl.t('supplier-invoices.success-toast.mark-as-paid');

    this.segment.track('supplier-invoices_mark-as-paid_clicked', {
      tab: invoice.status,
      is_non_financial_document: invoice.isAttachmentNonFinancial,
      page: invoice.status,
      origin_type: this.isLinkCreditNoteCtaDisplayed
        ? INVOICE_OR_CREDIT_NOTE_TEXT.creditNote
        : INVOICE_OR_CREDIT_NOTE_TEXT.invoice,
      ...this.germanEInvoiceEventFields,
    });

    this.modals.open(
      'supplier-invoices/transactions-modal',
      {
        isFullScreenModal: true,
        attachment: invoice.attachment,
        invoice,
        onCloseModal: () => {
          this.router.transitionTo('supplier-invoices.index');
          this.toastFlashMessages.toastSuccess(toastMessage);
        },
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
        },
      }
    );
  });

  payByTransfer = dropTask(async () => {
    // @ts-expect-error
    let { invoice, submitForm } = this.args;

    if (submitForm) {
      let submitted = await submitForm.perform();
      if (!submitted) return;
    }

    this.segment.track('supplier-invoices_pay-by-transfer_clicked', {
      is_non_financial_document: invoice.isAttachmentNonFinancial,
      ...this.germanEInvoiceEventFields,
    });

    if (variation('feature--boolean-ap-fx-out-integration')) {
      let legacyIban = invoice.iban;
      let supplierIban = invoice.supplierSnapshot?.iban || legacyIban;

      let supplierIbanIsNotSepa = Boolean(supplierIban && !isIbanFromSepaZone(supplierIban));
      let isFx = supplierIbanIsNotSepa || this.args.isSwift;

      if (isFx) {
        return this.flowLinkManager.transitionTo({
          name: 'international-out',
          stepId: 'quote',
          queryParams: {
            supplierInvoiceId: invoice.id,
            origin: TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES,
          },
        });
      }
    } else {
      let isFx = invoice.totalAmount && invoice.totalAmount.currency !== 'EUR';
      // The pay by transfer feature has not be linked to the new international-out transfer flow.
      // If the invoice is considered a non-SWIFT invoice, we should not redirect.
      // However, the condition should never be met, as the quick action has been disabled in that case.
      // We added this check as a simple safety net.
      if (isFx) {
        return;
      }
    }

    this.flowLinkManager.transitionTo({
      name: 'sepa-transfer',
      stepId: 'invoice',
      queryParams: {
        origin: TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES,
        supplierInvoiceId: invoice.id,
      },
    });
  });

  requestTransferTask = dropTask(async () => {
    // @ts-expect-error
    let { invoice, submitForm } = this.args;

    if (submitForm) {
      let submitted = await submitForm.perform();
      if (!submitted) return;
    }

    this.segment.track('supplier-invoices_request-transfer_clicked');
    this.router.transitionTo('requests.transfers.new', {
      queryParams: {
        supplierInvoiceId: invoice.id,
      },
    });
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::SidebarActions': typeof SidebarActionsComponent;
  }
}
