export default {
  "row": "qer",
  "disabled": "qeb",
  "cell": "qey body-2",
  "cell-link": "qeU",
  "cell-content": "qej",
  "item-truncate-text": "qeW",
  "item-name-cell": "qeu",
  "item-account-cell": "qBS",
  "icon": "qBc",
  "icon-download": "qBq"
};
