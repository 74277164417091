import { template as template_624af757541b46be932378f8c4686c12 } from "@ember/template-compiler";
import StepState from './step-state';
import styles from './steps.strict-module.css';
let item = template_624af757541b46be932378f8c4686c12(`
  <li class={{styles.step-container}} ...attributes>
    <StepState
      @isLastStep={{@isLastStep}}
      @isStepCompleted={{@isStepCompleted}}
      @isSuccessStep={{@isSuccessStep}}
      @isRejectedStep={{@isRejectedStep}}
      @isCurrentStep={{@isCurrentStep}}
      @isLoading={{@isLoading}}
    />
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const Steps = template_624af757541b46be932378f8c4686c12(`
  <ol class={{styles.steps-container}} ...attributes>
    {{yield item}}
  </ol>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Steps;
