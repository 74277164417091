export default {
  "bank-account-option": "qXM",
  "row": "qXP",
  "active": "qXl",
  "animated": "qXX",
  "fadein-item": "qXC",
  "cell": "qXk body-2",
  "cell-content": "qXJ",
  "subtitle": "qXs caption",
  "no-padding": "qXF",
  "empty": "qXg",
  "note": "qXD",
  "cell-content-amount": "qXV body-1",
  "cell-content-receipt": "qXL body-1",
  "cell-quick-actions": "qXT",
  "account-selector": "qXz",
  "cell-type": "qXa"
};
