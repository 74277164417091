export default {
  "wrapper": "qRC",
  "content": "qRk",
  "title": "qRJ",
  "aside": "qRs",
  "description": "qRF",
  "faq-link": "qRg",
  "close-btn": "qRD",
  "container": "qRV",
  "mandate-field-list": "qRL",
  "mandate-field-item": "qRT",
  "mandate-field-item__dot": "qRz",
  "mandate-field-item__text": "qRa"
};
