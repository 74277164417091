export default {
  "team-card": "qXf",
  "card-link": "qXK",
  "shadow": "qXG",
  "history-button": "qXr",
  "cards-button": "qXb",
  "menu-item": "qXy",
  "subtitle": "qXU",
  "delete-team": "qXj"
};
