export default {
  "attachment-sidebar": "qEz",
  "sidebar-scroll": "qEa",
  "top-section": "qEH",
  "timeline-section": "qEO",
  "sidebar-header": "qEm",
  "matched-section": "qEp",
  "collapsible-timeline": "qEx",
  "timeline-status-label": "qEw",
  "m-24": "qEN"
};
