export default {
  "cell": "qMS",
  "supplier-container": "qMc",
  "cell-label-container": "qMq",
  "cell-total-amount": "qMZ",
  "cell-label": "qMR",
  "overflow": "qMQ",
  "sub-label": "qMe body-2",
  "overdue": "qMB",
  "file-name-warning": "qME",
  "missing": "qMd",
  "pending-icon": "qMI",
  "icon": "qMt",
  "flex-label": "qMM",
  "with-separator": "qMP",
  "self-invoice-icon": "qMl",
  "tooltip-wrapper": "qMX",
  "credit-note-amount": "qMC",
  "payable-amount": "qMk",
  "amount": "qMJ"
};
