export default {
  "header-cell": "qlO",
  "header-content": "qlm caption-bold",
  "active": "qlp",
  "empty": "qlx",
  "align-right": "qlw",
  "type": "qlN",
  "requester": "qlv",
  "request-date": "qlo",
  "amount": "qln",
  "status": "qli"
};
